<template>
  <div class="image-viewer" v-if="isOpen" @click="closeImageViewer">
    <div class="image-container">
      <img :src="imageUrl" alt="Full Screen Image" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageUrl: String,
    isOpen: Boolean,
  },
  methods: {
    closeImageViewer() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.image-viewer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1000;
}

.image-container {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
  overflow: hidden; /* Dodane, aby zdjęcie nie wychodziło poza kontener */
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}
</style>
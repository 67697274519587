<template>
    <div id="app">
      <p class="shimmer-text">Strona Ślubna<br>Mai i Kamila</p>
      <div v-html="formatDate($store.state.weddingDate)" class="formatted-date"></div>
      <div>
        <div><WeddingCountdown /></div>
        <div class="container">
          <BrideContact />
          <GroomContact />
        </div>
        <div><WeddingPlace /></div>
        <div><ParkingPlace /></div>
        <div v-if="shouldShowTransportWays"><TransportWays /></div>
        <div v-if="shouldShowReceptionPlace"><ReceptionPlace /></div>
         <!--<div v-if="shouldShowPhotoGalery"><PhotoGalery /></div>-->
         <div><FlowerModule /></div>
         <div v-if="shouldShowGiftModule"><GiftModule /></div>
        </div>
        <router-view />
    </div>
  </template>
  
  <script>
  import WeddingCountdown from "@/components/WeddingCountdown";
  import GiftModule from "@/components/GiftModule";
  import FlowerModule from "@/components/FlowerModule";
  import ReceptionPlace from "@/components/places/ReceptionPlace";
  import TransportWays from "@/components/places/TransportWays";
  import WeddingPlace from "@/components/places/WeddingPlace";
  import ParkingPlace from "@/components/places/ParkingPlace";
  //import PhotoGalery from "@/components/photos/PhotoGalery";
  import BrideContact from "@/components/people/BrideContact";
  import GroomContact from "@/components/people/GroomContact";
  export default {
    components: {
      WeddingCountdown,
      GiftModule,
      FlowerModule,
      ReceptionPlace,
      TransportWays,
      WeddingPlace,
      ParkingPlace,
      //PhotoGalery,
      BrideContact,
      GroomContact
    },
    computed: {
      shouldShowTransportWays() {
        return this.$route.path === '/wesele';
      },
      shouldShowReceptionPlace() {
        return this.$route.path === '/wesele';
      },
      shouldShowGiftModule() {
        return this.$route.path === '/wesele';
      },
    },
    methods: {
        formatDate(date) {
  const options1 = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const options2 = {
    hour: 'numeric',
    minute: 'numeric',
  };

  const datePart = date.toLocaleDateString('pl-PL', options1);
  const timePart = date.toLocaleTimeString('pl-PL', options2);

  return `${datePart} <br> O godzinie: ${timePart}`;
},
    },
  };
  </script>
  <style>
  @import './fonts.css';
  body {
    font-size: 16px; /* Ustawienie rozmiaru tekstu dla całej strony */
    margin: 0;
    padding: 0;
    background-image: url('../public/tlo2.jpg'); /* Zmień 'sciezka/do/tla.jpg' na ścieżkę do twojego obrazu tła */
    background-size: cover;
    background-position: center;
    background-attachment: fixed; /* To sprawi, że tło nie będzie się przewijać */
    font-family: 'Arial', sans-serif; /* Możesz zmienić czcionkę według potrzeb */
    /* Dodaj inne stylizacje, takie jak kolor tekstu, marginesy itp. */
  }

    h1 {
    font-size: 70px; /* Przykładowe ustawienie rozmiaru dla nagłówków h1 */
    color: #000000;
    font-family: 'GreatVibes';
    text-shadow: 2px 2px 5px rgba(255, 255, 255, 0.8);
    }



.shimmer-text {
    font-weight: bold;
  font-family: 'GreatVibes', cursive; /* Załóżmy, że to jest Twoja czcionka Pacifico */
  font-size: 100px; /* Dostosuj wielkość czcionki według potrzeb */
  color: #000000; /* Kolor złoty */
  text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.8);
}


    p {
    font-size: 40px; /* Przykładowe ustawienie rozmiaru dla paragrafów */
    font-family:Arial, Helvetica, sans-serif;
    }
    .formatted-date {
    font-size: 40px; /* Przykładowe ustawienie rozmiaru dla paragrafów */
    font-family:Arial, Helvetica, sans-serif;
    }
    
    #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 8px;
    margin: 5px 0 20px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }
  
  label {
    margin-top: 20px;
    display: block;
    font-size: 18px;
  }
  
  h2 {
    margin-top: 40px;
  }

  .container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .container > * {
  margin: 10px; /* Dodany odstęp między elementami */
}


  body.modal-open {
  overflow: hidden;
}

a {
  color: #0f2135; /* Domyślny kolor linku (niebieski) */
  
  transition: color 0.3s ease; /* Dodanie efektu przejścia na zmianę koloru */
}

a:hover {
  color: #0056b3; /* Zmiana koloru po najechaniu myszą */
}

@media only screen and (max-width: 700px) {
    .shimmer-text {
    font-weight: bold;
  font-family: 'GreatVibes', cursive; /* Załóżmy, że to jest Twoja czcionka Pacifico */
  font-size: 50px; /* Dostosuj wielkość czcionki według potrzeb */
  color: #000000; /* Kolor złoty */
  text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.8);
}
p {
    font-size: 14px; /* Przykładowe ustawienie rozmiaru dla paragrafów */
    font-family:Arial, Helvetica, sans-serif;
    }
    h1 {
    font-size: 24px; /* Przykładowe ustawienie rozmiaru dla nagłówków h1 */
    color: #000000;
    font-family: 'GreatVibes';
    text-shadow: 2px 2px 5px rgba(255, 255, 255, 0.8);
    }
    .formatted-date {
    font-size: 24px; /* Przykładowe ustawienie rozmiaru dla paragrafów */
    }
}

  </style>



<template>
    <div>
      <h1>Transport:</h1>
      <p><a href="https://maps.app.goo.gl/b9ukUQfPQzFKeZeE8" target="_blank">Link do google maps</a></p>
      <p>Autokar zabierze osoby, które przyjadą przed slubem na salę o 14:45 i zabierze je pod kościół.</p>
      <p>Po ślubie również przewidziany jest autokar który zabierze wszystkich chętnych na salę.</p>
      <p>W nocy żaden transport autokarowy nie będzie dostępny, jednak bardzo chętnie pomożemy zorganizować Wam jakiś transport powrotny.</p>
    </div>
  </template>
  
<script>
export default {
};
</script>
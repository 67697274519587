<template>
  <div class="module-groom">
    <div class="background-image"></div>
    <div class="content">
      <p></p>
      <p><a href="tel:+48795578169">+48 795 578 169</a></p>
      <p><a href="mailto:kamil.mistewicz@gmail.com">kamil.mistewicz@gmail.com</a></p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.module-groom {
  position: relative;
  overflow: hidden;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../public/wizKamil.jpg'); /* Dodaj ścieżkę do zdjęcia */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: opacity(0.8); /* Dostosuj poziom przezroczystości */
  border-radius: 10%;
  opacity: 0.9;
}

.content {
  position: relative;
  z-index: 1; /* Ustaw na 1, aby zawartość była na wierzchu tła */
  margin-top: 23%;
  padding: 10px;
  line-height: 0.4;
  color: black;
}

</style>

<template>
  <div class="module-bride">
    <div class="background-image"></div>
    <div class="content">
      <p></p>
      <p><a href="tel:+48666088057">+48 666 088 057</a></p>
      <p><a href="mailto:maja.a.holub@gmail.com">maja.a.holub@gmail.com</a></p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.module-bride {
  position: relative;
  overflow: hidden;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../public/wizMaja.jpg'); 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: opacity(0.8); 
  border-radius: 10%;
  opacity: 0.9;
}
.content {
  position: relative;
  z-index: 1; 
  margin-top: 23%;
  padding: 10px;
  line-height: 0.4;
  color: black;
}

</style>

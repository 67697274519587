<template>
  <div>
    <p>{{ weddingStatusMessage }}</p>
    <p>{{ timeRemainingOrPassed }}</p>
  </div>
</template>

<script>
import { ref, watchEffect, onBeforeUnmount, onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();
    const weddingDate = ref(store.state.weddingDate);
    const currentDate = ref(new Date());
    const timeRemainingOrPassed = ref('');
    const weddingStatusMessage = ref('');
    let intervalId = null;

    const calculateTimeRemainingOrPassed = () => {
      const timeDifference = weddingDate.value - currentDate.value;
      const isWeddingOver = timeDifference < 0;

      if (isWeddingOver) {
        weddingStatusMessage.value = 'Od Ślubu minęło:';
      } else {
        weddingStatusMessage.value = 'Do Ślubu pozostało:';
      }

      const absoluteTimeDifference = Math.abs(timeDifference);

      const days = Math.floor(absoluteTimeDifference / (24 * 60 * 60 * 1000));
      const hours = Math.floor((absoluteTimeDifference % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
      const minutes = Math.floor((absoluteTimeDifference % (60 * 60 * 1000)) / (60 * 1000));
      const seconds = Math.floor((absoluteTimeDifference % (60 * 1000)) / 1000);

      timeRemainingOrPassed.value = `${days} dni, ${hours} godzin, ${minutes} minut, ${seconds} sekund`;
    };

    const startCountdown = () => {
      intervalId = setInterval(() => {
        currentDate.value = new Date(); // Aktualizacja currentDate co sekundę
      }, 1000);
    };

    const stopCountdown = () => {
      clearInterval(intervalId);
    };

    onMounted(() => {
      startCountdown();
    });

    onBeforeUnmount(() => {
      stopCountdown();
    });

    watchEffect(() => {
      calculateTimeRemainingOrPassed();
    });

    return {
      timeRemainingOrPassed,
      weddingStatusMessage,
    };
  },
};
</script>

<style scoped>
/* Dodaj stylizację według potrzeb */
</style>
<template>
    <div class="wp">
      <div class="info">
        <div class="centered-text">
          <h1>Miejsce wesela:</h1>
          <p><a href="https://www.google.com/maps/place/Rybacz%C3%B3wka/@52.0494516,21.2312109,17z/data=!3m1!4b1!4m6!3m5!1s0x4719291336d65041:0xb861485f337bf1e2!8m2!3d52.0494516!4d21.2312109!16s%2Fg%2F11grbt7xpy?hl=pl&entry=ttu" target="_blank">Sala weselna Rybaczówka</a></p>
          <p>Wiślana 32b, 05-480 Otwock Wielki</p>
        </div>
      </div>
      <div class="image-container">
        <a href="https://www.google.com/maps/place/Rybacz%C3%B3wka/@52.0494516,21.2312109,17z/data=!3m1!4b1!4m6!3m5!1s0x4719291336d65041:0xb861485f337bf1e2!8m2!3d52.0494516!4d21.2312109!16s%2Fg%2F11grbt7xpy?hl=pl&entry=ttu" target="_blank">
          <img src="/rybaczowka.jpg" alt="Rybaczowka" />
        </a>
      </div>
    </div>
  </template>
  
  <style scoped>
  .wp {
    display: flex;
    margin: 2%;
  }
  
  .image-container {
    flex: 1;
    position: relative;
    overflow: hidden;
  }
  
  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .info {
    flex: 1;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 20px;
    box-sizing: border-box;
  }
.centered-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
  </style>

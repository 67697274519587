<template>
    <div class="wp">
      <div class="image-container">
        <img src="/koperty.png" alt="Koperty" />
      </div>
      <div class="info">
        <div class="centered-text">
          <p>Bardziej niż prezenty przydadzą nam się koperty ze wsparciem na remont :)</p>
        </div>
      </div>
    </div>
  </template>
  <style scoped>
  .wp {
    display: flex;
    margin: 2%;
  }
  
  .image-container {
    flex: 1;
    position: relative;
    overflow: hidden;
  }
  
  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  
  .info {
    flex: 1;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 12px;
    box-sizing: border-box;
  }
  
  .centered-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  @media only screen and (max-width: 700px) {
p {
    font-size: 12px; /* Przykładowe ustawienie rozmiaru dla paragrafów */
    font-family:Arial, Helvetica, sans-serif;
    }
}
  </style>
<template>
    <div class="wp">
      <div class="info">
        <div class="centered-text">
          <h1>Gdzie zaparkować?</h1>
        </div>
      </div>
      <div class="image-container" @click="openImageViewer">
        <img src="/parking.png" alt="Parking" />
      </div>
      <ImageViewer :imageUrl="imageSrc" :isOpen="isImageViewerOpen" @close="closeImageViewer" />
    </div>
  </template>

    <script>
    import ImageViewer from "@/components/ImageViewer.vue";
    
    export default {
      data() {
        return {
          isImageViewerOpen: false,
          imageSrc: "/parking.png",
        };
      },
      methods: {
        openImageViewer() {
          this.isImageViewerOpen = true;
          document.body.style.overflow = "hidden";
        },
        closeImageViewer() {
          this.isImageViewerOpen = false;
          document.body.style.overflow = "";
        },
      },
      components: {
        ImageViewer,
      },
    };
    </script>

  <style scoped>
  .wp {
    display: flex;
    margin: 2%;
  }
  
  .image-container {
    flex: 1;
    position: relative;
    overflow: hidden;
  }
  
  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .info {
    flex: 1;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 20px;
    box-sizing: border-box;
  }
.centered-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
  </style>

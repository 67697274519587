<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style>
body {
  margin: 0;
  padding: 0;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  font-family: 'Arial', sans-serif;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    max-width: 90%; /* Maksymalna szerokość ramki */
    max-height: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    box-sizing: border-box;
    position: fixed; /* Ustawienie elementu jako nieruchomego */
    top: 0; /* Umieszczenie go na górze ekranu */
    left: 0; /* Umieszczenie go na lewej krawędzi */
    right: 0; /* Umieszczenie go na prawej krawędzi */
    overflow-y: auto; /* Umożliwienie przewijania pionowego */
    bottom: 0; /* Umieszczenie go na dole ekranu */
    scrollbar-width: thin; /* Dla Firefox */
    scrollbar-color: transparent transparent; /* Dla Firefox */
    overflow-y: auto;
  }
</style>
<template>
  <div class="wp">
    <div class="image-container">
      <a href="https://www.google.com/maps/place/Ko%C5%9Bci%C3%B3%C5%82+pw.+%C5%9Bw.+Stanis%C5%82awa+BM+parafii+%C5%9Bw.+Wojciecha/@52.2332337,20.9585327,17z/data=!4m15!1m8!3m7!1s0x471ecc91d311721f:0x3c5d4f228a03ab4b!2zT3NpZWRsZSBaYSDFu2VsYXpuxIUgQnJhbcSFLCBXYXJzemF3YQ!3b1!8m2!3d52.2372692!4d21.0032556!16s%2Fg%2F122rv93z!3m5!1s0x471ecb6508ebc617:0x551efb07f5fae153!8m2!3d52.2332184!4d20.9611004!16s%2Fg%2F1224hdtw?entry=ttu" target="_blank">
        <img src="/kosciol.jpg" alt="Kościół" />
      </a>
    </div>
    <div class="info">
      <div class="centered-text">
        <h1>Miejsce ślubu:</h1>
        <p><a href="https://www.google.com/maps/place/Ko%C5%9Bci%C3%B3%C5%82+pw.+%C5%9Bw.+Stanis%C5%82awa+BM+parafii+%C5%9Bw.+Wojciecha/@52.2332337,20.9585327,17z/data=!4m15!1m8!3m7!1s0x471ecc91d311721f:0x3c5d4f228a03ab4b!2zT3NpZWRsZSBaYSDFu2VsYXpuxIUgQnJhbcSFLCBXYXJzemF3YQ!3b1!8m2!3d52.2372692!4d21.0032556!16s%2Fg%2F122rv93z!3m5!1s0x471ecb6508ebc617:0x551efb07f5fae153!8m2!3d52.2332184!4d20.9611004!16s%2Fg%2F1224hdtw?entry=ttu" target="_blank">Kościół pw. św. Stanisława BM parafii św. Wojciecha</a></p>
        <p>Wolska 76, 01-134 Warszawa</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.wp {
  display: flex;
  margin: 2%;
}

.image-container {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  flex: 1;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 20px;
  box-sizing: border-box;
}

.centered-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
</style>